<template>
  <div id="page">
    <div id="content-area">
      <h1>Betingelser og vilkår</h1>
      <section class="align-left">
        <p class="sub">Copyright 12. februar 2021</p>
        <p class="sub">Legal Notice – Terms and conditions for Responding Companies</p>
        <p class="sub">Velliv Foreningen, All rights reserved.</p>
        <p>Læs venligst de følgende vilkår og betingelser, før du opretter en profil på Core Culture og bruger målingen samt Core Culture indsatserne (herefter kaldet platformen). Enhver brug af platformen og platformens indhold, ligger under for de følgende vilkår og betingelser. Din og/eller din virksomheds brug af platformen indikerer accept af disse vilkår og betingelser. Hvis du ikke accepterer vilkårene og betingelserne, kan du ikke bruge platformen og dens indhold.</p>
        <h4>Accept af vilkår</h4>
        <p>Core Culture-platformen er ejet af Velliv Foreningen. Velliv Foreningen må til enhver tid vælge at ændre vilkår, betingelser og drift af platformen, uden forudgående varsling. Ved at bruge denne service, fraskriver brugeren sig alle muligheder for at gøre indsigelser mod Velliv Foreningen samt udviklerne af værktøjet (Aron - center for bæredygtigt arbejdsliv og Human House). Som følge heraf, giver brugeren sin accept af vilkårene og betingelserne, når brugeren tilgår, besøger og/eller bruger platformen. Ved at bruge platformen, har brugeren accepteret vilkår og betingelser. Brugeren må ikke ændre, modificere eller tilpasse platformen på nogen måde.</p>
        <h4>Varemærke</h4>
        <p>Velliv Foreningens og Core Cultures varemærker og logoer som bliver vist og brugt på platformen, er enten varemærker eller beskyttede varemærker ejet af Velliv Foreningen. Produkter og virksomheder og organisationers navne som bliver nævnt på platformen, er muligvis varemærker eller beskyttede varemærker hos de respektive ejere.</p>
        <h4>Afgrænsninger for ansvar</h4>
        <p>Hverken Velliv Foreningen eller deres ledelse, ansatte eller andre repræsentanter (herunder udviklerne af platformen), kan gøres ansvarlige for skader opstået i forbindelse med brug af platformen. Dette er en omfattende afgrænsning af ansvar, der omfatter alle skader af enhver art, eksempelvis, men ikke begrænset til, kompensatoriske, direkte, indirekte eller konsekventielle skader, tab af data, indkomst eller profit. Information på denne platform indeholder muligvis unøjagtigheder eller grammatiske fejl, som Velliv Foreningen ikke tager ansvar for. Velliv Foreningen tager ikke ansvar for andre hjemmesider som du kan tilgå gennem platformen. Da Velliv Foreningen ikke har nogen kontrol over tredjeparts hjemmesider og disses indhold, fraskriver Velliv Foreningen sig ethvert ansvar for tilgængelighed, indhold og brug af såvel produkter, services mm. som gøres tilgængeligt via sådanne tredjeparts hjemmesider.</p>
        <p>Information på hjemmesiden er stillet til rådighed ”som det er”, uden garanti af nogen art, enten eksplicit eller implicit, eksempelvis, men ikke begrænset til implicitte garantier for salgbarhed, egnethed til et bestemt formål eller ikke-overtrædelse. Visse jurisdiktioner tillader ikke eksklusionen af implicitte garantier, så den ovennævnte eksklusion gælder muligvis ikke for dig.</p>
        <h4>Datasikkerhed</h4>
        <p class="sub">Indsamling og lagring af data</p>
        <p>Platformen består af flere forskellige underliggende systemer, der er integreret gennem moderne og testede standarder, for bedst at sikre brugernes datasikkerhed. Al data som en virksomhed eller et individ har indsendt, vil blive gemt sikkert som beskrevet i nedenstående:</p>
        <p><em>Hosting</em><br />Kommer snart...</p>
        <p><em>Kryptering</em><br />Alle udvekslinger af fortrolig information mellem browseren og platformen, håndteres via SSL.</p>
        <p><em>Databehandling</em><br />Velliv Foreningen behandler dine data med henblik på at brugeren får mest muligt ud af af platformen.</p>
        <p><em>Dataudveksling</em><br />Alle data som en respondent indsender, er i udgangspunktet ikke delt med eller tilgængelig for andre, udover brugeren og Velliv Foreningen.<br />Kun respondenten har adgang til sine egne ikke-anonymiserede data og indekstal.</p>
        <p><em>Sletning af data</em><br />Brugeren kan vælge at slette sin individuelle Core Culture-profil på ethvert tidspunkt via hjemmesiden. Når man har valgt at få slettet en profil, vil følgende data blive slettet fra platformens systemer og databaser:
          <ul><li>Virksomhedens/individets login og andre data, der eksplicit identificerer virksomheden eller individet</li></ul>
        </p>
        <h4>Brug af data</h4>
        <p>
          Core Culture-platformen er bygget med fokus på, at sikre brugernes personhenførbare data bedst muligt. Velliv Foreningen kan bruge respondenternes anonymiserede data til alle formål som Velliv Foreningen ønsker det, mens Velliv Foreningen altid vil henholder sig til datasikkerhedspolitikken og beskyttelsen af respondenterne, som defineret i disse vilkår og betingelser. Eksempler på brug kunne være, men er ikke begrænset til:
          <ul>
            <li>Akademisk forskning</li>
            <li>Analyse af anonymiseret data</li>
            <li>Udgivelse af artikler og rapporter baseret på data</li>
          </ul>
        </p>
        <p>Denne brug af anonymiseret data er ikke personhenførbar, men baseret på brugerens anonymiserede testresultater.</p>
        <h4>Cookies</h4>
        <p>På hjemmesiden anvender Velliv Foreningen fmba cookies i overensstemmelse med nærværende cookiepolitik.</p>
        <h4>Hvad er en cookie?</h4>
        <p>En cookie er en passiv datafil, som gemmes på din computer, smartphone, tablet eller andet IT-udstyr, når du besøger en hjemmeside. En cookie kan ikke sprede virus eller andre skadelige programmer, men gør det muligt at analysere, hvilke sider og funktioner der bruges på hjemmesiden. Samtidig kan den også være med til at forbedre brugeroplevelsen.</p>
        <p>Nogle cookies placeres af andre parter (såkaldte 3. parter) end den, som står i adresselinjen (URL’en) i browseren. Det kan være almindeligt indhold, men også f.eks. analyseværktøjer eller indlejrede kommentarfelter. Det betyder, at der gemmes cookies fra andre parter, end den som ejer hjemmesiden.</p>
        <p>Nogle cookies gemmes kun på brugerens IT-udstyr, så længe brugeren har sin browser åben (sessionscookies). Andre cookies gemmes i et længere tidsrum (persistente cookies). Når brugeren gen-besøger en hjemmeside, vil sessions-cookies blive sat på ny, mens persistente cookies typisk fornyes.</p>
        <h4>Vores formål med brugen af cookies</h4>
        <p>Vi bruger cookies til at finde fejl på hjemmesiden og analysere brugen af hjemmesiden, herunder bl.a. hvor mange besøgende vi har, hvilke sider der vises mest, og hvordan brugervenligheden kan optimeres.</p>
        <p>Første gang, du besøger vores hjemmeside, bliver du spurgt om, hvorvidt du kan acceptere vores brug af cookies. Der er frivilligt, men vi opfordrer dig til at acceptere brugen af cookies, da de hjælper os med at forbedre hjemmesiden og dermed din oplevelse på hjemmesiden.</p>
        <p>Bemærk, at din fortsatte brug af vores hjemmeside uden din aktive stillingstagen til cookies vil blive betragtet som om, at du har accepteret, at vi bruger cookies. Nederst på denne side kan du se en oversigt over de forskellige cookies, vi bruger.</p>
        <h4>Slå cookies fra</h4>
        <p>Ønsker du ikke at acceptere cookies fra vores hjemmeside, kan du i de fleste nyere browsere vælge avancerede cookie-indstillinger under internet-indstillinger og tilføje dette domæne til listen over hjemmesider, du vil blokere cookies fra. Du kan også slette individuelle cookies eller alle de cookies, som din browser har gemt. Hvis du sætter din browser op til at nægte cookies, skal du være opmærksom på, at der kan være funktionaliteter på forskellige hjemmesider, herunder også vellivforeningen.dk, der ikke virker.</p>
        <p>Du kan nedenfor finde links til vejledninger i at slette cookies fra udbyderne af de mest almindelige browsere. Hvis du vil fjerne cookies fra din smartphone eller tablet, henviser vi til brugervejledningen for disse. Velliv Foreningen anvender Google Analytics, og du kan via et browser plug-in, Google's Opt-Out Browser Add-on, undgå cookies fra Google Analytics. Vær opmærksom på, at dine besøg på andre sider, der anvender Google Analytics, heller ikke vil blive registreret, hvis du installerer dette browser plug-in. </p>
        <p>Du kan læse mere om Google Analytics' anvendelse af cookies <a href="" target="_blank">her.</a></p>
        <ul>
          <li><a href="" target="_blank">Vejledning i at slette cookies i Microsoft Internet Explorer</a></li>
          <li><a href="" target="_blank">Vejledning i at slette cookies i Mozilla Firefox</a></li>
          <li><a href="" target="_blank">Vejledning i at slette cookies i Google Chrome</a></li>
          <li><a href="" target="_blank">Vejledning i at slette cookies i Opera</a></li>
          <li><a href="" target="_blank">Vejledning i at slette cookies i Safari</a></li>
          <li><a href="" target="_blank">Vejledning i at slette flash cookies (gælder alle browsere)</a></li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    anonym: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.$emit('update-background', 'rgb(253,252,248)')
  },
  methods: {
    redirect () {
      if (this.$route.query.redirect) {
        this.$router.replace({ path: this.$route.query.redirect })
      } else {
        this.$router.replace({ path: '/' })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
