<template>
  <div id="content-area">
    <h1>
      Målingsværktøjet er gratis - støttet af <br />
      <img :src="logoUrl" alt="" width="350" style="margin: -20px;">
    </h1>
    <p>I er kun tre trin fra at kunne måle arbejdsfællesskabet og få anbefalinger til indsatser.</p>
    <ol class="list">
      <li>
        Opret profil
      </li>
      <li>
        Del link til spørgeskema med medarbejderne
      </li>
      <li>
        Modtag en rapport med resultater og anbefalinger
      </li>
    </ol>
    <div class="form-section">
      <h2>Opret profil / login</h2>
      <h4 class="error-message" v-if="errorMessage">{{errorMessage}}</h4>
      <div class="input-container">
        <input type="text" placeholder="virksomhedsemail" v-model="user.email">
      </div>
      <div class="input-container">
        <input type="password" placeholder="password" v-model="user.password">
      </div>
      <div class="input-container checkbox">
        <input type="checkbox" id="terms" v-model="termsAcceptet">
        <label for="terms">Jeg har læst <a :href="`${baseUrl}/#/vilkaar`" target="_blank">betingelser</a> for brug (vi opbevarer jeres data sikkert og deler dem ikke med tredjepart)</label>
      </div>
      <div>
        <p></p>
      </div>
      <nav class="horizontal-flex">
        <base-ui-button @clicked="signIn">
          Log ind
        </base-ui-button>
        <base-ui-button @clicked="userRegistration" primary>
          Opret profil
        </base-ui-button>
      </nav>
      <p>Har du glemt dit password? Så nulstil det <router-link to="/nulstil-password">her</router-link></p>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

import BaseUiButton from '../components/shared/BaseUiButton.vue'
import { errorMessages } from '../sharedFunctions/errorMessages'
import logoUrl from '@/assets/imgs/velliv-foreningen-logotype-primary-orange-rgb.svg'

export default {
  name: '',
  components: { BaseUiButton },
  data () {
    return {
      logoUrl: logoUrl,
      user: {
        email: '',
        password: ''
      },
      termsAcceptet: false,
      errorMessage: '',
      message: 'Log ind for at se eksisterende måling, resultater og anbefalinger, samt få link til deling af spørgeskema i din organisation.'
    }
  },
  computed: {
    baseUrl () {
      return window.location.origin
    }
  },
  mounted () {
    if (this.$store.state.user.loggedIn) {
      this.redirect()
    }
    this.$emit('update-background', 'rgb(253,252,248)')
  },
  methods: {
    redirect () {
      if (this.$route.query.redirect) {
        this.$router.replace({ path: this.$route.query.redirect })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    close () {
      if (this.$route.query.from) {
        this.$router.replace({ path: this.$route.query.from })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    signIn () {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then(data => {
          this.redirect()
        })
        .catch((e) => {
          // this.message = e.code
          this.errorMessage = errorMessages[e.code] || 'Der skete en fejl, prøv igen senere '
        })
    },
    userRegistration () {
      if (this.termsAcceptet) {
        firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password)
          .then((res) => {
            console.log('CREATE USER', res)
            if (res.user) {
              this.redirect()
              // this.$store.dispatch('updateUser', res.user)
              /* firebase
                .auth()
                .signInWithEmailAndPassword(this.user.email, this.user.password)
                .then(data => {
                  console.log('redirecting')
                }) */
            } else {
              this.errorMessage = 'Der skete en fejl, prøv igen senere '
            }
          })
          .catch((e) => {
            this.errorMessage = errorMessages[e.code] || 'Der skete en fejl, prøv igen senere '
          })
      } else {
        this.errorMessage = 'Du skal acceptere vilkår for brug for at kunne oprette en profil'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

#content-area
  box-sizing border-box
  // min-height 800px
  height auto
  justify-content center
  max-width 40rem
  margin auto
  display flex
  flex-flow column
  align-items center
  text-align center
  padding 3rem

.list {
  text-align left
}
.form-section {
  max-width 23rem
  margin-top 1rem
}
.form-section h2 {
  margin-bottom 2rem
}
.input-container label {
  line-height: 1.3 !important;
}
nav
  justify-content center

.error-message
  color: red;
</style>
