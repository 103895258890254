<template>
  <div id="content-area">
    <h2>Du er nu logget ud.</h2>
    <base-ui-button @clicked="$router.push('/dashboard')" primary>Ok</base-ui-button>
  </div>
</template>
<script>
import BaseUiButton from '../components/shared/BaseUiButton.vue'
export default {
  components: { BaseUiButton },
  emits: ['update-background']
}
</script>
<style lang="stylus" scoped>
#content-area
  justify-content center
</style>
